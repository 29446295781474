import "@lottiefiles/lottie-player";
import { gsap } from "../../node_modules/gsap/index.js";
import { ScrollTrigger } from "../../node_modules/gsap/ScrollTrigger.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

gsap.registerPlugin(ScrollTrigger);

const circleAnimations = $$("main > section .circle-animation");
const headings = $$("main > section h1, main > section h2, main > section h3, main > section .big-text, main > div h1, main > div h2, main > div h3, main > div .big-text");

let circleStart = "top 25%";
let headlineStart = "top center";
const motionCheck = window.matchMedia('(prefers-reduced-motion: reduce)');
const mediaQuery = window.matchMedia('(max-width: 768px)');

const circleAnimation = `
  <lottie-player
    mode="normal"
    src="images/circles.json"
  ></lottie-player>
`;

if (mediaQuery.matches) {
  circleStart = "top 75%";
  headlineStart = "top 80%";
}
if (motionCheck.matches) {
  circleStart = "top bottom";
}

if (circleAnimations.length > 0) {
  circleAnimations.forEach(function(animation) {
    let played = false;
    const triggerInstance = ScrollTrigger.create({
      trigger: animation,
      start: circleStart,
      end: "top top",
      onEnter: ({progress, direction, isActive}) => {
        if (isActive && !played) {
          $(".animation", animation).innerHTML = circleAnimation;
          let player = $("lottie-player", animation);
          $("lottie-player", animation).addEventListener("rendered", (e) => {
            if (motionCheck.matches) {
              player.seek(100);
            } else {
              player.play();
            }
          })
          animation.classList.add("reveal");
          played = true;
        }
      },
      onEnterBack: ({progress, direction, isActive}) => {
        if (isActive && !played) {
          $(".animation", animation).innerHTML = circleAnimation;
          let player = $("lottie-player", animation);
          $("lottie-player", animation).addEventListener("rendered", (e) => {
            if (motionCheck.matches) {
              player.seek(100);
            } else {
              player.play();
            }
          })
          animation.classList.add("reveal");
          played = true;
        }
      }
    });
  })
}

if (headings.length > 0) {
  headings.forEach(function(heading) {
    const triggerInstance = ScrollTrigger.create({
      trigger: heading,
      start: headlineStart,
      end: "top top",
      onEnter: ({progress, direction, isActive}) => {
        if (isActive) {
          heading.classList.add("fade");
        }
      },
      onEnterBack: ({progress, direction, isActive}) => {
        if (isActive) {
          heading.classList.add("fade");
        }
      }
    });
  })
}