const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const embeds = $$(".video-embed");

const youtubeRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;


embeds.forEach((embed) => {

  const link = $("a[href*='youtu.be'],a[href*='youtube.com']", embed);

  const url = link.href;
  const queryID = url.match(youtubeRegex);
  if (queryID !== null) {
    const id = queryID[1];

    link.addEventListener("click", function(event) {
      event.preventDefault();
      const replacementDiv = document.createElement('div');
      const videoEmbed = `<iframe class="w-full aspect-[2.345]" src="https://www.youtube.com/embed/${ id }?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      link.parentNode.innerHTML = videoEmbed;
    })
  }
})
