import "@lottiefiles/lottie-player";
import dialogPolyfill from '../../node_modules/dialog-polyfill/dist/dialog-polyfill.esm.js';
import { gsap } from "../../node_modules/gsap/index.js";
import { ScrollTrigger } from "../../node_modules/gsap/ScrollTrigger.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

gsap.registerPlugin(ScrollTrigger);

const motionCheck = window.matchMedia('(prefers-reduced-motion: reduce)');
const buttons = $$("button");
const circleAnimation = `
  <lottie-player
    mode="normal"
    src="images/circles.json"
  ></lottie-player>
`;

let headlineStart = "top center";

const dialogs = $$("dialog");
dialogs.forEach(function(dialog){
  dialogPolyfill.registerDialog(dialog);
});

buttons.forEach(function(button) {
  const dialogID = button.getAttribute("data-dialog");
  const dialog = $(dialogID);

  if (dialog) {
    dialog.addEventListener('close', function (e) {
      $("body").classList.remove("overflow-hidden");
    });

    button.addEventListener("click", function() {
      dialog.showModal();
      $("body").classList.add("overflow-hidden");
      dialog.classList.add("start-transition");
      dialog.scrollTop = 0;

      const headlines = $$("h1,h2,h3,.big-text",dialog);
      const circleAnimations = $$(".circle-animation",dialog);
      const players = $$("lottie-player", dialog);
      if (circleAnimations.length > 0 && players.length === 0) {
        circleAnimations.forEach(function(animation) {
          $(".animation", animation).innerHTML = circleAnimation;
          let player = $("lottie-player", animation);
          $("lottie-player", animation).addEventListener("rendered", (e) => {
            if (motionCheck.matches) {
              player.seek(100);
            } else {
              player.play();
            }
          })
          animation.classList.add("reveal");
        })
      }
      headlines.forEach((headline) => {
        //headline.classList.add("fade");
        const triggerInstance = ScrollTrigger.create({
          scroller: dialog,
          trigger: headline,
          start: headlineStart,
          end: "top top",
          onEnter: ({progress, direction, isActive}) => {
            if (isActive) {
              headline.classList.add("fade");
            }
          },
          onEnterBack: ({progress, direction, isActive}) => {
            if (isActive) {
              headline.classList.add("fade");
            }
          }
        });
      });
    });

    $$(".close", dialog).forEach(function(close){
      close.addEventListener("click", function() {
        dialog.close();
        $("body").classList.remove("overflow-hidden");
      })
    })
  }
});